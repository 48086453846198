import React from 'react'
import { Global, css } from '@emotion/react'

export const breakpoints =  {
	mobile: '@media (max-width: 768px)',
	mobileUp: '@media (min-width: 769px)',
	tablet: '@media (max-width: 1024px)',
	desktop: '@media (max-width: 1550px)',
	desktopUp: '@media (min-width: 1025px)'
}

export const mobile = breakpoints.mobile
export const tablet = breakpoints.tablet
export const desktop = breakpoints.desktop
export const desktopUp = breakpoints.desktopUp


const GlobalStyles = () => (
	<>
		<Global styles={reset} />
		<Global styles={styles} />
	</>
)

const styles = css`

	@keyframes colorFade {
			0%   {background-color: var(--yellow);}
			20%  {background-color: var(--orange);}
			40%  {background-color: var(--red);}
			60%  {background-color: var(--pink);}
			80%  {background-color: var(--blue);}
			100% {background-color: var(--yellow);}
	}

  body {
		font-family: 'Lausanne', sans-serif;
		font-size: 24px;
		line-height: 1.16;
		font-style: normal;
		font-weight: 300;
		letter-spacing: -0.01em;
		background-color: var(--lightGrey);
		overflow: unset !important;
		${desktop}{
			font-size: 20px;
		}
		${tablet}{
			font-size: 18px;
		}
  }

  :root{
    --white: #ffffff;
    --black: #000000;
		--grey1: #333333;
		--orange: #FFA04C;
		--red: #E95D3C;
		--blue: #7C94F6;
		--pink: #F9A5E9;
		--yellow: #D8FFA4;
		--lightGrey: #F7F7FC;
		/* Desktop spacing */
		--xxl: 410px;
		--xl: 271px;
		--l: 132px;
		--m: 77px;
		--s: 66px;
		--m-xl: 117px;
		--m-l: 67px;
		--m-m: 47px;
		--m-s: 35px;
  }

  ${tablet}{
    :root{
    }
  }

  p{
    margin-bottom: 1.1em;
		min-height: 15px;
    &:last-child{
      margin-bottom: 0;
    }
  }

  .h1-sans {
    font-size: 98px;
    line-height: 1.12;
		letter-spacing: -0.01em;
		${desktop}{
			font-size: 80px;
		}
		${tablet}{
			font-size: 70px;
			line-height: 1.25;
		}
		${mobile}{
			font-size: 58px;
			line-height: 1.25;
		}
  }

	h2,
  .h2-sans {
    font-size: 35px;
    line-height: 1.14;
		letter-spacing: -0.01em;
		${desktop}{
			font-size: 28px;
		}
		${tablet}{
			font-size: 26.25px; 
		}
  }

	.filter {
		font-size: 72px;
    line-height: 1.15;
		letter-spacing: -0.01em;
		${desktop}{
			font-size: 59px;
		}
		${tablet}{
			font-size: 54px; 
		}
	}
	
	.sm-sans {
		font-size: 24px;
    line-height: 1.13;
		letter-spacing: 0em;
		${desktop}{
			font-size: 20px;
		}
		${tablet}{
			font-size: 18px;
		}
	}

	.h1-serif {
		font-family: 'TimesNow';
		font-size: 72px;
    line-height: 1;
		letter-spacing: -0.03em;
		${desktop}{
			font-size: 56px;
		}
		${tablet}{
			font-size: 54px;
		}
		${mobile}{
			font-size: 35px;
		}
	}

	.h2-serif {
		font-family: 'TimesNow';
		font-size: 40px;
    line-height: 1;
		letter-spacing: -0.03em;
		${desktop}{
			font-size: 32.8px;
		}
		${tablet}{
			font-size: 28px;
		}
	}

	.h3-serif {
		font-family: 'TimesNow';
		font-size: 28px;
    line-height: 1.14;
		letter-spacing: 0em;
		${desktop}{
			font-size: 23px;
		}
		${tablet}{
			font-size: 21px;
		}
	}

	.ReactCollapse--collapse {
		transition: height 0.3s cubic-bezier(0.5, 1, 0.89, 1);
	}
`
const reset = css`

  body {
    margin: 0px;
    min-height: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: inherit;
    font-size: inherit;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  ul, ol, blockquote {
    margin: 0;
    padding: 0;
  }

  li {
    margin: 0.5em 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
		/*  This prevents chrome from annoyingly adjusting the scroll to try and compensate for moving elements */
		overflow-anchor: none;
  }

  button{
    -webkit-appearance: none;
    border: none;
    background: none;
    font-size: inherit;
    font-family: inherit;
    cursor: pointer;
    padding: 0;
    margin: 0;
		letter-spacing: inherit;
  }

	input{
		font-family: inherit;
	}

  input:-webkit-autofill,
	input:-webkit-autofill:hover, 
	input:-webkit-autofill:focus,
	textarea:-webkit-autofill,
	textarea:-webkit-autofill:hover,
	textarea:-webkit-autofill:focus,
	select:-webkit-autofill,
	select:-webkit-autofill:hover,
	select:-webkit-autofill:focus {
		border: none;
		-webkit-text-fill-color: inherit;
		-webkit-box-shadow: 0 0 0px 0px transparent inset;
		transition: background-color 5000000s ease-in-out 0s;
	}

	button, option {
		color: inherit;
	}
	
`

export default GlobalStyles
