import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, useStaticQuery } from 'gatsby'
import styled from '@emotion/styled'
import { DateTime } from 'luxon'
import { useLocation } from '@reach/router'
import MenuAnimation from '~components/MenuAnimation'
import { Global, css } from '@emotion/react'
import useWindow from '~utils/useWindow'
import { disablePageScroll, enablePageScroll } from 'scroll-lock'
import Logo from '~components/Logo'
import Headroom from 'react-headroom'
import SanityLink from '~components/SanityLink'
import { mobile, tablet, desktop } from '~styles/global'
import useSiteSettings from '~utils/useSiteSettings'


const HeaderLink = ({ className, item, onClick }) => {
	const [hover, setHover] = useState()

	return(
		<LinkWrap 
			className={`${className} h1-sans`} 
		>
			<Trigger 	
				onMouseEnter={() => setHover(true)}
				onMouseLeave={() => setHover(false)}
			>
				<SanityLink link={item.link} onClick={onClick}>
					{item.text}
				</SanityLink>
				<UnderlineWrap>
					<Underline hover={hover}/>
				</UnderlineWrap>
			</Trigger>
		</LinkWrap>
	)
}

const LinkWrap = styled.h3`
	pointer-events: none;
`
const Trigger = styled.div`
	position: relative;
	display: inline;
	pointer-events: all;
`
const UnderlineWrap = styled.div`
	display: block;
	height: 4px;
	overflow: hidden;
	position: absolute;
	left: 0;
	right: 0;
	top: calc(100% - 6px);
	${desktop}{
		top: calc(100% - 5px);
	}
	${tablet}{
    top: calc(100% - 3px);
	}
	${mobile}{
		display: none;
	}
`
const Underline = styled.div`
	background-color: var(--black);
	width: ${props => props.hover ? '100%' : '0px'};
	height: 100%;
	transition: width 0.25s;
`
const Header = ({ className, onChange }) => {
	const [menuOpen, setMenuOpen] = useState(false)
	const loc = useLocation()
	const toggleMenu = () => setMenuOpen(prev => !prev)
	const w = useWindow()
	const [pinned, setPinned] = useState()

	const {
		emailAdress 
	} = useSiteSettings()

	useEffect(() => {
		setMenuOpen(false)
	}, [loc.pathname])

	useEffect(() => {
		if(menuOpen){
			// disablePageScroll
			document.body.style.height = window.innerHeight + 'px'
			document.body.style.overflow = 'hidden'
			document.documentElement.style.height = window.innerHeight + 'px'
			document.documentElement.style.overflow = 'hidden'
		} else {
			// enablePageScroll
			document.body.style.height = 'auto'
			document.body.style.overflow = 'auto'
			document.documentElement.style.height = 'auto'
			document.documentElement.style.overflow = 'auto'
		}
		if(onChange){
			onChange(menuOpen)
		}
	}, [menuOpen])

	const data = useStaticQuery(graphql`
		{
			nav: sanityNavigation {
				navigationLinks {
					text
					_key
					link {
						...link
					}
				}
				socialLinks {
					...button
				}
			}
		}
	`)

	return(
		<>
			<Wrap className={className}>
				<Date>
					<SanityLink
						link={{
							url: `mailto:${emailAdress}?subject=Today's the day!`
						}}
					>
						{DateTime.now().setZone('Australia/Melbourne').toFormat('dd/MM/yy')}
					</SanityLink>
				</Date>
				<LogoWrap>
					<Headroom
						disableInlineStyles
						onUnfix={() => setPinned(true)}
						onUnpin={() => setPinned(false)}
						// onUnfix={() => alert('unfix')}
						onFix={() => alert('fix')}
						downTolerance={20}
						upTolerance={20}
					>
						<Link to={'/'} onClick={() => setMenuOpen(false)}><Logo pinned={pinned} /></Link>
					</Headroom>
				</LogoWrap>
				<MenuButton>
					<MenuButtonInner onClick={toggleMenu}>{menuOpen ? 'Close' : 'Menu'}</MenuButtonInner>
				</MenuButton>
			</Wrap>
			<Menu open={menuOpen} h={w.innerHeight + 'px'}>
				<Nav>
					{data.nav.navigationLinks.map(item =>
						<HeaderLink item={item} key={item.key} onClick={() => setMenuOpen(false)}/>
					)}
				</Nav>
				<SocialLinks>
					{data.nav?.socialLinks.map(link => 
						<SocialLink link={link.link} key={link.text} onClick={() => setMenuOpen(false)}>
							{link.text}
						</SocialLink>
					)}
				</SocialLinks>
				<Animation menuOpen={menuOpen} />
			</Menu>
		</>
	)
}

const Wrap = styled.header`
  position: fixed;
  top: 0;
  right: 0;
	left: 0;
  z-index: 55;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	padding: 25px;
	text-transform: uppercase;
	align-items: top;
	mix-blend-mode: difference;
  color: var(--white);
	${mobile}{
		padding: 20px;
		grid-template-columns: max-content 1fr;
	}
}
	button {
		text-transform: uppercase;
		line-height: 1;
	}
`
const Date = styled.div`
	${mobile}{
		display: none;
	}
`
const LogoWrap = styled.div`
	text-align: center;
	margin-top: 0.04em;
`
const MenuButton = styled.div`
		text-align: right;
		text-transform: uppercase;
`
const MenuButtonInner = styled.button`
	text-transform: uppercase;
`

const Menu = styled.menu`
	background: #F7F7FC;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	height: ${props => props.h};
	z-index: 50;
	padding: 0;
	margin: 0;
	display: ${props => props.open ? 'grid' : 'none'};
	text-align: center;
	grid-template-columns: 1fr;
`

const Nav = styled.nav`
	z-index: 10;
	align-self: center;
	display: grid;
	pointer-events: none;
`

const Animation = styled(MenuAnimation)`
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9;
	cursor: grab;
	&:active{
		cursor: grabbing;
	}
	canvas {
		width: 100vw;
	}
`

const SocialLinks = styled.div`
	position: fixed;
	bottom: 25px;
	margin-left: 50%;
	transform: translateX(-50%);
	z-index: 10;
	${mobile}{
		bottom: 25px;
	}
`
const SocialLink = styled(SanityLink)`
	:not(:last-of-type){
		:after {
			content: ' / ';
			${mobile}{
				content: '';
			}
		}
		${mobile}{
			margin-bottom: 6px;
		}
	}
	${mobile}{
		display: block;
		width: 100%;
	}
`

Header.propTypes = {
	className: PropTypes.string,
	onChange: PropTypes.func,
}

HeaderLink.propTypes = {
	className: PropTypes.string,
	item: PropTypes.object,
	onClick: PropTypes.func,
}

export default Header
