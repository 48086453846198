module.exports = {
	siteUrl: 'https://dateofbirth.com.au',
	buildsUrl: 'https://dob.gtsb.io',
	previewUrl: 'https://preview-dob.gtsb.io',
	previewWebhook: 'https://webhook.gatsbyjs.com/hooks/data_source/22f29d22-ac5e-4754-8a80-4415d3ca50c4',
	mailchimpUrl: 'https://dobcreative.us12.list-manage.com',
	mailchimpUser: 'a93b8dc0c539f725e74115f50',
	mailchimpId: '56334f3b8b',
	gTag: 'G-6G7TD7T27E'
}
