import { graphql, useStaticQuery } from 'gatsby'

const useSiteSettings = () => {

	const siteSettings = useStaticQuery(graphql`
    query {
      sanitySiteSettings {
        siteSocialImage: socialImage {
          asset {
            _id
          }
        }
        siteMetaDescription: metaDescription
        siteTitle
      }
			sanityFooter {
				bookingButton {
					...button
				}
    		bookingText: _rawBookingText(resolveReferences: {maxDepth: 5})
				phoneNumber
				startProjectButton{
					...button
				}
				socialLinks {
					link {
						...link
					}
					text
					_key
				}
				extraLinks {
					link {
						...link
					}
					text
					_key
				}
				emailAdress
			}
			sanityContactPage {
				footerSlug: slug {
					current
				}
			}
    }
  `)

	return {...siteSettings?.sanitySiteSettings, ...siteSettings.sanityFooter, ...siteSettings.sanityContactPage}
}

export default useSiteSettings
