import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { useSiteState } from '~context/siteContext'
import useBreakpoint from '~utils/useBreakpoint'


const Cursor = ({ className}) => {
	const [siteState] = useSiteState()
	const [mouseX, setMouseX] = useState()
	const [mouseY, setMouseY] = useState()
	const { isMobile } = useBreakpoint()

	const setCursor = e => {
		setMouseX(e.clientX)
		setMouseY(e.clientY)
	}

	useEffect(() => {
		window.addEventListener('mousemove', e => setCursor(e))
	}, [])

	return (
		<Wrap 
			className={className} 
			show={siteState.customCursor && !isMobile}
			style={{top: mouseY + 'px', left: mouseX + 'px'}}
		>
			<Dot 
				color={siteState.cursorColor}
			/>
			{siteState.cursorMessage}
		</Wrap>
	)
}

const Wrap = styled.div`
	/* left: ${props => props.mouseX}px;
	top: ${props => props.mouseY}px; */
	position: fixed;
	opacity: ${props => props.show ? '1': '0'};
  pointer-events: none;
	display: flex;
	align-items: center;
	z-index: 100;
	white-space: nowrap;
`
const Dot = styled.div`
	background-color: ${props => props.color ? props.color : 'var(--black)'};
	min-width: 25px;
	min-height: 25px;
	border-radius: 50px;
	margin-right: 10px;
	position: relative;
	bottom: 3px;
`

Cursor.propTypes = {
	className: PropTypes.string,
	cursorMessage: PropTypes.string,
	color: PropTypes.string,
}

export default Cursor