/* eslint-disable react/prop-types */
import React from 'react'

export const Face = ({ className }) => (
	<svg className={className} viewBox="0 0 99 106" fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx="20" cy="20" r="20" fill="currentColor"/>
		<path d="M49.5 105.5C76.8381 105.5 99 83.3381 99 56H0C0 83.3381 22.1619 105.5 49.5 105.5Z" fill="currentColor"/>
		<circle cx="79" cy="20" r="20" fill="currentColor"/>
	</svg>
)

export const Logo = ({ className }) => (
	<svg viewBox="0 0 209 209" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
		<path d="M39.2293 120.363H18.3711V122.453H39.2293V120.363Z" fill="currentColor"/>
		<path d="M63.3689 120.363H42.5107V122.453H63.3689V120.363Z" fill="currentColor"/>
		<path d="M102.911 120.363H82.0532V122.453H102.911V120.363Z" fill="currentColor"/>
		<path d="M127.051 120.363H106.193V122.453H127.051V120.363Z" fill="currentColor"/>
		<path d="M83.8299 86.7559H86.8604L74.5294 117.939H71.478L83.8299 86.7559Z" fill="currentColor"/>
		<path d="M166.594 120.363H145.736V122.453H166.594V120.363Z" fill="currentColor"/>
		<path d="M190.754 120.363H169.896V122.453H190.754V120.363Z" fill="currentColor"/>
		<path d="M147.512 86.7559H150.564L138.212 117.939H135.181L147.512 86.7559Z" fill="currentColor"/>
	</svg>
)