import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import Section from '~components/Section'
import useSiteSettings from '~utils/useSiteSettings'
import SanityLink from '~components/SanityLink'
import DataViewer from '~utils/DataViewer'
import { mobile, tablet, desktop } from '~styles/global'

const Footer = ({ className }) => {
	const {
		phoneNumber,
		socialLinks,
		extraLinks,
		emailAdress,
		startProjectButton
	} = useSiteSettings()

	return(
		<Wrap className={className}>
			<Section className='sm-sans'>
				<Line />
				<Col >
					<div>© {new Date().getFullYear()} Copyright</div>
					<div>Date Of Birth Pty Ltd</div>
				</Col>
				<Col>
					{/* <Email 
						href=""
						link={{
							url: `mailto:${emailAdress}`
						}}
					>
						{emailAdress}
					</Email> */}
					<SanityLink link={startProjectButton?.link}>
						{startProjectButton.text}
					</SanityLink>
					<Tel href={`tel:${phoneNumber}`}>
						{phoneNumber}
					</Tel>
				</Col>
				<Col>
					{socialLinks.map(link => (
						<FooterLink key={link.key} link={link.link}>{link.text}</FooterLink>
					))}	
				</Col>
				<ColFooter>
					{extraLinks.map(link => (
						<FooterLink key={link.key} link={link.link}>{link.text}</FooterLink>
					))}	
				</ColFooter>
			</Section>
		</Wrap>
	)
}

const Wrap = styled.footer`
`
const Line = styled.div`
	border-bottom: 1px solid var(--black);
	grid-column: span 16;
	margin-bottom: 42px;
`
const Col = styled.div`
	grid-column: span 4;
	margin-bottom: 38px;
	${desktop}{
		grid-column: span 8;
	}
	${mobile}{
		grid-column: span 16;
		margin-bottom: 28px;
	}
`
const ColFooter = styled(Col)`
	margin-right: 126px;
	${mobile}{
		margin-right: 100px;
	}
`
const Email = styled(SanityLink)`
	
`
const Tel = styled.a`
	display: block;
`
const FooterLink = styled(SanityLink)`
	display: block;
`

Footer.propTypes = {
	className: PropTypes.string,
}

export default Footer
