import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet, desktop } from '~styles/global'
import * as logoData from '~utils/logoData.json'
import * as logoDataMobile from '~utils/logoDataMobile.json'
import lottie from 'lottie-web'
import useWindow from '~utils/useWindow'

const Logo = ({ className, reverse, noSpace, pinned }) => {
	const [animation, setAnimation] = useState()
	const [animationMobile, setAnimationMobile] = useState()
	const containerRef = useRef()
	const mobileContainerRef = useRef()
	const w = useWindow()
	const [prevY, setPrevY] = useState(0)
	const [scrollDir, setScrollDir] = useState()
	let dirRecentlyChanged = false
	let delayedChange


	useEffect(() => {
		if(containerRef?.current){
			const anim = lottie.loadAnimation({
				container: containerRef.current,
				renderer: 'svg',
				loop: false,
				autoplay: false,
				animationData: logoData
			})
			setAnimation(anim)
		}
	}, [containerRef])

	useEffect(() => {
		if(mobileContainerRef?.current){
			const anim = lottie.loadAnimation({
				container: mobileContainerRef.current,
				renderer: 'svg',
				loop: false,
				autoplay: false,
				animationData: logoDataMobile
			})
			setAnimationMobile(anim)
		}
	}, [mobileContainerRef])

	const changeDir = dir => {
		if(dirRecentlyChanged) return
		dirRecentlyChanged = true
		setScrollDir(dir)
		clearTimeout(delayedChange)
		delayedChange = window.setTimeout(() => {
			dirRecentlyChanged = false
		}, 1000)
	}

	useEffect(() => {
		if((animation || animationMobile) && prevY !== 0){
			if(w?.scrollY > prevY ){
				changeDir('down')
			} else {
				changeDir('up')
			}
		}
		setPrevY(w?.scrollY)
	}, [w?.scrollY])

	useEffect(() => {
		if(animation){
			animation.setSpeed(2)
			if(!pinned){
				animation.playSegments([0, 50], true)
			} else {
				animation.playSegments([60, 108], true)
			}
		}
		if(animationMobile){
			animationMobile.setSpeed(2)
			if(!pinned){
				animationMobile.playSegments([0, 50], true)
			} else {
				animationMobile.playSegments([60, 108], true)
			}
		}
	}, [pinned])

	return (
		<>
			<Wrap className={className}>
				<div ref={containerRef} css={css`
					${mobile}{
						display: none;
					}
					svg {
						display: block;
						line-height: 1;
						path {
							fill: #ffffff !important;
						}
					}
				`}/>
				<div ref={mobileContainerRef} css={css`
					display: none;
					${mobile}{
						display: block;
					}
					svg {
						display: block;
						line-height: 1;
						path {
							fill: #ffffff !important;
						}
					}
				`}/>
			</Wrap>
		</>
	)
}

const Wrap = styled.div`
	z-index: 15;
	width: 230px;
	margin: 0 auto;
	/* ${desktop}{
		width: 210px;
	} */
	${tablet}{
		width: 155px;
	}
	${mobile}{
		width: 150px;
		transform: translateY(1px);
	}
`

Logo.propTypes = {
	className: PropTypes.string,
	reverse: PropTypes.bool,
	noSpace: PropTypes.bool,
}

export default Logo