import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet, desktop } from '~styles/global'
import { Logo } from '~components/Svg'
import { Link, graphql, useStaticQuery } from 'gatsby'
import useSiteSettings from '~utils/useSiteSettings'
import useBreakpoint from '~utils/useBreakpoint'

const FloatingLogo = ({ className, location, menuOpen }) => {

	const [hide, setHide] = useState(false)
	const { isMobile } = useBreakpoint()

	const data = useStaticQuery(graphql`
		{
			sanityProjects {
				slug {
					current
				}
			}
		}
	`)

	useEffect(() => {
		if (isMobile && menuOpen) {
			setHide(true)
		}
		else if(location.pathname.includes('/' + data?.sanityProjects?.slug?.current + '/')) {
			setHide(true)
		} else {
			setHide(false)
		}
	}, [location.pathname, menuOpen])

	const {
		footerSlug
	} = useSiteSettings()

	return (
		<Wrap className={className } hide={hide}>
			<Link to={`/${footerSlug?.current}`}>
				<StyledLogo />
			</Link>
		</Wrap>
	)
}



const Wrap = styled.div`
	position: fixed;
	bottom: 25px;
  right: 25px;
	z-index: 200;
	animation-name: colorFade;
	animation-duration: 6s;
	animation-iteration-count: infinite;
	background-color: var(--yellow);
	width: 105px;
	height: 103px;
	display: ${props => props.hide ? 'none' : 'flex'};
	align-items: center;
	justify-content: center;
	${desktop}{
		width: 88px;
    height: 86px;
	}
	${mobile}{
		bottom: 20px;
  	right: 20px;
		width: 70px;
    height: 68px;
	}
`

const StyledLogo = styled(Logo)`
	width: 96px;
	height: 96px;
	${desktop}{
		width: 80px;
    height: 80px;
	}
	${mobile}{
		width: 66px;
		height: 66px;
		position: relative;
		top: 3px;
	}
`

FloatingLogo.propTypes = {
	className: PropTypes.string,
	location: PropTypes.object,
	menuOpen: PropTypes.bool,
}

export default FloatingLogo