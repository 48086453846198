import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { 
	Engine, 
	Render, 
	Bodies, 
	World, 
	Mouse, 
	MouseConstraint, 
	Runner 
} from 'matter-js'
import useWindow from '~utils/useWindow'

const MenuAnimation = ({ className, menuOpen }) => {
	const scene = useRef()
	const engine = useRef(Engine.create())
	let mouse = useRef()
	let mouseConstraint = useRef()
	let throttleTimeout
	const w = useWindow()

	useEffect(() => {
		if(!menuOpen) return null
		const cw = window.innerWidth * 2
		const ch = window.innerHeight * 2

		const render = Render.create({
			element: scene.current,
			engine: engine.current,
			options: {
				width: cw,
				height: ch,
				wireframes: false,
				background: 'transparent'
			}
		})

		const staticOptions = { 
			isStatic: true, 
			render: {
				fillStyle: '#ffffff',
			} 
		}

		World.add(engine.current.world, [
			// top
			Bodies.rectangle(cw / 2, 0 - (ch * 8), cw, 50, staticOptions),
			// left
			Bodies.rectangle(-10, ch / 2, 20, ch * 10, staticOptions),
			// bottom
			Bodies.rectangle(cw / 2, ch + 25, cw, 50, staticOptions),
			// right
			Bodies.rectangle(cw + 25, ch / 2, 50, ch * 10, staticOptions)
		])

		const runner = Runner.run(engine.current)
		Render.run(render)

		addBlocks()
		
		// add mouse control
		mouse.current = Mouse.create(render.canvas)

		mouseConstraint.current = MouseConstraint.create(engine.current, {
			mouse: mouse.current,
			constraint: {
				stiffness: 0.2,
				render: {
					visible: false
				}
			}
		})

		mouse.current.element.removeEventListener('mousewheel', mouse.current.mousewheel)
		mouse.current.element.removeEventListener('DOMMouseScroll', mouse.current.mousewheel)

		World.add(engine.current.world, mouseConstraint.current)

		// keep the mouse in sync with rendering
		render.mouse = mouse.current
		
		return () => {
			Render.stop(render)
			World.clear(engine.current.world)
			Engine.clear(engine.current)
			Runner.stop(runner)
			render.canvas.remove()
			render.canvas = null
			render.context = null
			render.textures = {}
		}
	}, [menuOpen, w?.innerWidth, w?.innerHeight])

	const addBlocks = () => {
		const cw = window.innerWidth * 2
		const ch = window.innerHeight * 2
		let scale = window.innerWidth / 900
		engine.current.gravity.scale = 0.0025

		if(window.innerWidth <= 1024){
			scale = window.innerWidth / 600
			engine.current.gravity.scale = 0.002
		}

		if(window.innerWidth <= 768){
			scale = window.innerWidth / 500
			engine.current.gravity.scale = 0.0015
		}

		const RectColors = [
			'#D8FFA4', 
			'#7C94F6', 
			'#FFA04C', 
			'#F9A5E9', 
			'#E95D3C'
		]

		const rects = RectColors.map(color => {
			let rectangleWidth = 500 * scale
			let rectangleHeight = 200 * scale
			let availableX = cw - rectangleWidth
			let availableY = ch - rectangleHeight
			let randomX = Math.random() * availableX
			let randomY = Math.random() * availableY - ch
			return Bodies.rectangle(
				randomX,
				randomY,
				rectangleWidth,
				rectangleHeight,
				{
					render: {
						fillStyle: color,
						friction: 0.001,
					}
				}
			)
		})

		World.add(engine.current.world, rects)

		rects.forEach(rect => {
			rect.angle = (Math.random() - 0.5) * 0.1
		})

	}

	const handleMouseLeave = () => {
		// remove the mousedown when not on the canvas
		mouse.current.button = -1
	}

	return (
		<div
			className={className}
			onMouseLeave={handleMouseLeave}
		>
			<div ref={scene} style={{ width: '100%', height: '100%' }} />
		</div>
	)
}

MenuAnimation.propTypes = {
	className: PropTypes.string,
	menuOpen: PropTypes.bool,
}

export default MenuAnimation